<template>
  <section class="Login">
    <header class="Login-header">
      <img
        src="@/assets/images/logo.png"
        alt="VehPark - Vehicles I need"
        class="logo"
      />

      <b-button @click="loginWithGoogle">{{ $t('login_google') }}</b-button>

      <img
        src="@/assets/images/logo-mobidata.png"
        alt="Logo Mobivia Database"
        class="logo"
      >
    </header>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

export default {
  name: "Login",
  methods: {
    ...mapActions('user', ['setToken']),
    loginWithGoogle() {
      this.$gAuth.getAuthCode().then(authCode => {
        return axios.post(`${process.env.VUE_APP_BACKEND}/auth/google`, {
          code: authCode,
          redirect_uri: "postmessage"
        })
      }).then(response => {
        if (response.data.id_token) {
          const decoded = jwtDecode(response.data.id_token)
          const token = {
            jwtToken: response.data.id_token,
            brands: response.data.ens,
            decoded
          }

          this.setToken(token)

          this.$router.push('/header')
        } else {
          if (response) {
            if (response.data && response.data.message == "Forbidden user") {
              console.log(
                `The account you used to log in is currently not authorized to access to the application.
                Make sure you use an authorized account or contact team to be granted access to the application.`
              )
            } else if (
              response.data &&
              response.data.message == "Token timeout"
            ) {
              // should never enter this clause in reality but who knows, just in case
              console.log("La connexion a expiré !")
            } else {
              console.log("La connexion a échoué !")
            }
          } else {
            console.log("La connexion a échoué !")
          }
        }
      }).catch(error => {
        console.log(error);
        console.log("La connexion a échoué !")
      })
    }
  }
}
</script>
